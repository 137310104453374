import React from "react";
import Image from "gatsby-image";

export default function ClientLogos({ clients, title }) {
    let grid_classes = "grid md:grid-cols-2 md:gap-5 xl:grid-cols-4";

    if (title) grid_classes = "grid md:grid-cols-2 md:gap-5 xl:grid-cols-5";

    const renderTitle = () => {
        if (title) {
            return (
                <div className="col-span-1 md:col-span-2 xl:col-span-1 flex align-center justify-center mb-5 xl:mb-0 ">
                    <h2 className="self-center bg-primary p-3 w-full xl:w-auto text-white text-center text-base title">{title}</h2>
                </div>
            )
        }
    };

    return (
        <div className={"my-16 excerpt-grid " + grid_classes}>
            { renderTitle() }

            { clients.map(( client, index) => {

                return (
                    <a href={client.link} target="_blank" rel="noreferrer" title={client.name} className="mb-5 xl:mb-0 flex items-center justify-center w-full" key={index}>
                        <Image fluid={client.image} alt={client.name} className="w-full max-w-xxs xl:max-w-xs"/>
                    </a>
                )
            }) }
        </div>
    )
};
