import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {graphql} from "gatsby";
import ExcerptGrid from "../components/excerpt-grid";
import ClientLogos from "../components/client-logos";

export default function IndexPage({data}) {
    const masthead_slides = data.slides.edges.map(obj => {
        let rObj = {};
        rObj.image = obj.node.frontmatter.image.childImageSharp.fluid;
        return rObj
    });

    const excerpts = data.excerpts.edges.map(obj => {
        let rObj = {};
        rObj.path = obj.node.frontmatter.path;
        rObj.title = obj.node.frontmatter.title;
        rObj.image = obj.node.frontmatter.image.childImageSharp.fluid;
        return rObj
    });

    const clients = data.clients.edges.map(obj => {
        let rObj = {};
        rObj.name = obj.node.frontmatter.name;
        rObj.link = obj.node.frontmatter.link;
        rObj.image = obj.node.frontmatter.image.childImageSharp.fluid;
        return rObj
    });
    
    return(
        <Layout masthead={{display: true, slides: masthead_slides}}>
            <SEO title="Home" />

            <div className={"mb-8 px-6 text-center lg:mb-10 lg:px-12 xl:px-20"}>
                <h2 className={"leading-tight md:leading-snug"}>Ken Judge & Associates Ltd are Building Surveyors and Architectural Consultants providing services to a diverse range of clients within residential, commercial and education sectors.</h2>
                <p className={"mt-8 font-display text-primary md:text-lg lg:mt-10 lg:text-xl"}>We are led by a team with 40+ years’ experience and our Clients best interests are our principle concern. Our friendly, professional team deal with all projects in strict confidence. We are proud to acknowledge that our ever-increasing workload is maintained by recommendation and returning clients.</p>
            </div>

            <ExcerptGrid excerpts={excerpts} excerptClasses="featured-call-to-action"/>

            <ClientLogos clients={clients} title="Working With"/>
        </Layout>
    )
}

export const query = graphql`
  query {
      slides: allMarkdownRemark(filter: { fileAbsolutePath: {
            glob: "**/content/index/slides/*.md"
        } }, sort: {fields: fileAbsolutePath}) {
        edges {
          node {
            frontmatter {
              image {
                childImageSharp {
                    fluid(maxWidth: 2000) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                }
              }
            }
          }
        }
      }
      excerpts: allMarkdownRemark(filter: { fileAbsolutePath: {
            glob: "**/content/index/excerpts/*.md"
        } }) {
        edges {
          node {
            frontmatter {
              path
              title
              image {
                childImageSharp {
                    fluid(maxWidth: 2000) {
                      ...GatsbyImageSharpFluid
                    }
                }
              }
            }
          }
        }
      }
      clients: allMarkdownRemark(filter: { fileAbsolutePath: {
            glob: "**/content/clients/details/*.md"
        } }) {
        edges {
          node {
            frontmatter {
              name
              link
              image {
                childImageSharp {
                    fluid(maxWidth: 320) {
                      ...GatsbyImageSharpFluid
                    }
                }
              }
            }
          }
        }
      }
  }
`
